import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DoDontRow = makeShortcode("DoDontRow");
const DoDont = makeShortcode("DoDont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Anatomy`}</h2>
    <p>{`The carousel component typically has a container element that holds all the slides and defines the overall layout and dimension of the component. The component includes a set of slides which are the visual elements of the carousel, it can be images, videos, or other types of content. Navigation buttons are usually located on either side of the carousel that allows the user to navigate through the slides. The component also includes indicators that allow the user to know the current slide and the number of slides available, it can be represented as dots or numbers. Some carousels also include a caption or a title for each slide, that gives more information about the slide.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/carousel/carousel-component-guideline-anatomy.png",
      "alt": "carousel antomy banner"
    }}></img>
    <ol>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Container`}</strong>{` : holds all the slides and defines the overall layout and dimension of the component.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Content`}</strong>{` : visual elements of the carousel, can be images, videos, or other types of content.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Navigation`}</strong>{` : located on either side of the carousel that allows the user to navigate through the slides.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Indicators`}</strong>{` : allows the user to know the current slide and the number of slides available, represented as dots or numbers.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Title/Description`}</strong>{` : provides more information about the slide.`}</p>
      </li>
    </ol>
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`Carousels can be used in a variety of ways in user interface design:`}</p>
    <ul>
      <li parentName="ul">{`Showcasing products on e-commerce websites: Carousels can be used to display multiple images of a product, providing a way for users to view different angles or details of the product.`}</li>
      <li parentName="ul">{`Displaying a series of images on a landing page: Carousels can be used to cycle through a series of images that showcase the features or benefits of a product or service.`}</li>
      <li parentName="ul">{`Rotating through featured content on a blog or news website: Carousels can be used to highlight the most recent or popular articles or posts on a website.`}</li>
      <li parentName="ul">{`Rotating through a set of testimonials: Carousels can be used to display a set of customer testimonials, featuring quotes or reviews from satisfied customers.`}</li>
      <li parentName="ul">{`Creating a background for a website: Carousels can be used to create a dynamic background for a website by cycling through a series of images or videos.`}</li>
      <li parentName="ul">{`Creating a slideshow for a presentation: Carousels can be used to create a slideshow for a presentation, allowing the presenter to move through the slides with ease.`}</li>
    </ul>
    <div className="row1">
  <div className="double-column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#429777"
        }}>
      When to use
    </p>
    <p className="text-md mb-2">
      Carousels can be a useful design element in certain situations, but they also have some drawbacks. When deciding
      whether to use a carousel, consider the following:
    </p>
    <ul>
      <li>
        Use carousels when you have multiple items that you want to showcase and you want to give users the ability to view
        them all in a limited space.
      </li>
      <li>
        Use carousels when you want to highlight different products or services on your website, but you don't want to
        clutter the page with multiple sections.
      </li>
      <li>
        Use carousels when you want to create a dynamic background for your website or present a slideshow in a
        presentation.
      </li>
    </ul>
    <p className="text-md mb-2">
      However, it's worth noting that carousels can be overlooked by users, because they often move automatically and users
      might not notice them. Also, Carousels can be confusing for users, especially if they move too quickly or if the
      navigation is not clear. It's important to consider the user experience and accessibility when using carousels.
    </p>
  </div>
  <div className="column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#e42313"
        }}>
      When not to use
    </p>
    <p className="text-md mb-2">
      There are certain situations in which a carousel may not be the best design choice:
    </p>
    <ul>
      <li>
        Avoid using carousels when the information contained within them is important and should always be visible to users.
      </li>
      <li>
        Avoid using carousels when the information contained within them is time-sensitive, as it can become outdated
        quickly if the user doesn't see it.
      </li>
      <li>
        Avoid using carousels when the information contained within them is complex or requires a lot of attention, as the
        automatic rotation can be disorienting or overwhelming.
      </li>
      <li>
        Avoid using carousels when the information contained within them is not useful for all users, as it can be an
        unnecessary distraction for some users.
      </li>
      <li>
        Avoid using carousels if the website or application is going to be accessed by users with accessibility needs, as
        the automatic movement and the lack of ability to pause or control the speed can make it difficult for some users to
        consume the content.
      </li>
    </ul>
    <p className="text-md mb-2">
      It is important to consider the user experience and the purpose of the website or application when deciding whether or not to use a carousel. If the information contained within a carousel is not crucial or if it's not the most effective way to present the information, it may be best to find an alternative design solution.
    </p>
  </div>
    </div>
    <div className="divi" />
    <h2>{`Accessibility`}</h2>
    <p>{`Carousels can present accessibility challenges for users with certain disabilities. Here are a few things to keep in mind to ensure that carousels are accessible:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Provide clear, visible navigation controls: Make sure that the controls for moving through the slides (such as next and previous buttons) are clearly visible and easy to use.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Allow users to pause the carousel: Provide a way for users to pause the carousel if they need more time to read or understand the content on a slide.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Provide alternative ways to navigate: In addition to the controls for moving through the slides, provide alternative ways to navigate, such as using the keyboard arrow keys or swipe gestures on touch devices.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Use appropriate ARIA roles: Use appropriate ARIA roles, such as “carousel” and “slide,” to indicate the purpose of different elements within the carousel.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Provide descriptive text: Provide descriptive text for each slide, such as captions or labels, to help users understand the content of the slide.`}</p>
      </li>
    </ol>
    <p>{`It’s also important to test the carousel with assistive technologies and with users with disabilities to ensure that it is fully accessible.`}</p>
    <p>{`It’s important to consider the accessibility when working with carousels, and make sure to provide alternative ways to access the content, as well as providing clear and visible controls, and descriptive text.`}</p>
    <DoDontRow mdxType="DoDontRow">
      <DoDont type="do" captionTitle="Do" caption="Only one slider can be used in the same row." aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/carousel/guideline/carousel-component-guideline-do.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't" caption="Used more than one slider in the same row." aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/carousel/guideline/carousel-component-guideline-dont.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <h2>{`Content`}</h2>
    <p>{`Here are some tips for creating and developing content for carousels:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Keep the number of slides limited: Too many slides can be overwhelming for users, so keep the number of slides in the carousel to a manageable number, ideally between 3 to 5 slides.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Make sure the images or content are visually appealing: Make sure the images or content are of high quality, visually appealing and easy to read.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Use consistent formatting: Use consistent formatting for all the slides in the carousel, such as using the same font, color scheme, and formatting for text, this will make the carousel look more cohesive.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Keep the text brief: Keep the text on the slides brief and to the point, users will not spend a lot of time reading each slide, so make sure the main message is conveyed quickly.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Use clear and concise headlines: Use clear and concise headlines that grab the user’s attention and accurately convey the main message of the slide.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Use calls to action: Include calls to action on the slides, such as “Learn More” or “Shop Now,” to encourage users to take a specific action.`}</p>
      </li>
    </ul>
    <p>{`When creating and developing content for carousels, it’s important to keep the number of slides limited, make sure that the images or content are visually appealing and easy to read, use consistent formatting, keep the text brief, use clear and concise headlines, and include calls to action to encourage users to take a specific action.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      